<template>
    <div>
      <div class="text-center mb-2">
        <el-radio-group v-model="field.is_global_variable" >
          <el-radio-button :label="false">Custom Field</el-radio-button>
          <el-radio-button :label="true">Global Variable</el-radio-button>
        </el-radio-group>
      </div>
  
      <el-form label-position="right" v-if="!field.is_global_variable">
        <title-and-description :field="field" />
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <placeholder :field="field" />
          </el-col>
          <el-col :span="12">
            <field-filled-by :field="field" />
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
          <is-field-required :field="field" class="field-required"/>
          </el-col>
        </el-row>
      </el-form>
  
      <el-form label-position="right" v-if="field.is_global_variable">
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <div class="form-group">
            
              <el-form-item label="Global Variable">
                <el-select v-model="field.global_variable_id" @change="setGlobalVariable">
                  <el-option
                    v-for="(globalVariable,index) of allGlobalVariables"
                    :key="index"
                    :label="globalVariable.label"
                    filterable
                    :value="globalVariable._id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="30">
          <el-col :span="12">
            <placeholder :field="field" />
          </el-col>
          <el-col :span="12">
            <field-filled-by :field="field" />
          </el-col>
        </el-row>
        <is-field-required :field="field" class="field-required"/>
      </el-form>
    </div>
  </template>
  
  <script>
  // import TitleAndDescription from "./TitleAndDescription";
  // import IsFieldRequired from "./IsFieldRequired";
  // import FieldFilledBy from "./FieldFilledBy";
  // import FieldAttributes from "./FieldAttributes";
  // import Placeholder from "./Placeholder";
  import { mapGetters } from "vuex";
  export default {
    name:"templates-formComponents-StarRating",
    components: {
       "TitleAndDescription":()=>import("./TitleAndDescription"),
      "IsFieldRequired":()=>import("./IsFieldRequired"),
      "FieldFilledBy":()=>import("./FieldFilledBy"),
      "Placeholder":()=>import("./Placeholder"),
    },
    
    props: ["field"],
    computed: {
      ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
      allGlobalVariables() {
        console.log("getAllGlobalVariables",this.getAllGlobalVariables);
        return this.getAllGlobalVariables
          ? this.getAllGlobalVariables.data || []
          : [];
      }
    },
    mounted() {
      this.fetchGlobalVaribales();
    },
    data() {
      return {};
    },
    methods: {
      async fetchGlobalVaribales() {
       
        let params = {
          get_all: true,
          input_type: this.field.input_type
        };
  
        await this.$store.dispatch(
          "globalVariables/fetchGlobalVariables",
          params
        );
      },
      setGlobalVariable() {
        let globalVariable = this.allGlobalVariables.find(
          x => x._id == this.field.global_variable_id
        );
        this.field.label = globalVariable.label;
        this.field.description = globalVariable.description;
        this.field.options = globalVariable.options;
      }
    }
  };
  </script>
  
  <style lang="scss">
  </style>